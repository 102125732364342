import React from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <nav className="relative header">
                <div className="absolute top-0 bottom-0 left-0 right-0 pointer-events-none">
                    <img alt="header-background" src="/images/header-background.svg"/>
                </div>
                <div className="z-10 relative">
                    <NavBar/>
                </div>
                <div className="p-4 max-w-screen-lg mx-auto">
                    <p className="text-[24px] font-bold mb-2" style={{
                        fontFamily: "SF Pro Display, Arial, sans-serif",
                        fontStyle: "normal",
                        fontWeight: 800,
                        lineHeight: "129%",
                        color: "#E6E6E6",
                    }}>PrimeVault Privacy Policy</p>
                    <p className="mb-4 text-[14px]"
                       style={{
                           fontFamily: "PingFang SC, Arial, sans-serif",
                           fontStyle: "normal",
                           fontWeight: 400,
                           lineHeight: "160%",
                           color: "#E6E6E6",
                           fontFeatureSettings: "'salt' on, 'liga' off",
                       }}>Last updated: August 2023</p>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Introduction</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            PrimeVault is a Delaware, USA registered company with headquarters in San Francisco, CA.
                            PrimeVault provides institutional and enterprise customers end-to-end solutions to manage
                            their digital asset operations at scale including direct asset custody, customizable
                            security
                            settings including role-based access controls, direct access to on and off chain
                            markets/trading venues,
                            risk management, compliance and more.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            At PrimeVault, we are committed to protecting your privacy and ensuring the security of your
                            personal information. This Privacy Policy outlines how we collect, use, disclose, and
                            protect
                            the information you provide to us through our institutional-grade crypto and DeFi trading,
                            custody and risk management platform ("Platform"). We recognize that information privacy is
                            an ongoing responsibility, and so we will from time to time update our Privacy Policy as we
                            undertake new personal data practices or adopt new privacy policies. By accessing or using
                            our Platform, you consent to the practices described in this Privacy Policy.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Prospect and customer data we collect</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            PrimeVault collects both personal and non-personal information about its website visitors
                            and customers to provide a better user experience. The types of information we collect
                            include:
                        </p>
                        <br/>
                        <strong className="text-[16px]" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "160%",
                            color: "#B7BECB",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Personal Information:</strong>
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            color: '#B7BECB',  // Setting color to the List
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                color: '#B7BECB', // Setting color to each ListItem
                            },
                            '& .MuiListItemText-root': {
                                color: '#B7BECB' // Overriding color for text inside ListItemText
                            }
                        }}>
                            <ListItem>
                                <ListItemText
                                    primary="Contact Information: Name, job title, employer name, work email address, work phone number and work address"
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Identification Information: Government-issued identification documents (e.g., passport, driver's license) for identity verification purposes."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Account Information: Username, Organization ID and other account-related details."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                        </List>

                        <strong className="text-[16px]" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "160%",
                            color: "#B7BECB",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Financial Information:</strong>
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            color: '#B7BECB',  // Setting color to the List
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                color: '#B7BECB', // Setting color to each ListItem
                            },
                            '& .MuiListItemText-root': {
                                color: '#B7BECB' // Overriding color for text inside ListItemText
                            }
                        }}>
                            <ListItem>
                                <ListItemText
                                    primary="Cryptocurrency Wallet Addresses: To facilitate transactions and account management."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Transaction Data: Details of transactions made through the Platform."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Payment Information: Credit card, bank account, or other payment details used for transactions."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                        </List>

                        <strong className="text-[16px]" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "160%",
                            color: "#B7BECB",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Technical Information:</strong>
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            color: '#B7BECB',  // Setting color to the List
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                color: '#B7BECB', // Setting color to each ListItem
                            },
                            '& .MuiListItemText-root': {
                                color: '#B7BECB' // Overriding color for text inside ListItemText
                            }
                        }}>
                            <ListItem>
                                <ListItemText
                                    primary="Device Information: Device type, operating system, browser type, IP address, and other technical details."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Usage Information: Interaction with the Platform, including page views, clicks, and session duration."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                        </List>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>How We Use Your Information</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            We use the collected information for various purposes, including but not limited to:
                        </p>
                        <List sx={{
                            listStyleType: 'disc',
                            pl: 2,
                            color: '#B7BECB',  // Setting color to the List
                            '& .MuiListItem-root': {
                                display: 'list-item',
                                color: '#B7BECB', // Setting color to each ListItem
                            },
                            '& .MuiListItemText-root': {
                                color: '#B7BECB' // Overriding color for text inside ListItemText
                            }
                        }}>
                            <ListItem>
                                <ListItemText
                                    primary="Providing, improving, and personalizing our services."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Verifying your identity and complying with legal and regulatory requirements."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Processing transactions and managing your account."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Communicating with you regarding your account, transactions, and updates."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Analyzing user behavior and preferences to enhance our Platform's functionality."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Detecting and preventing fraudulent activities and security breaches."
                                    className="text-[16px]" style={{
                                    fontFamily: "PingFang SC, Arial, sans-serif",
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: "160%",
                                    color: "#B7BECB",
                                    fontFeatureSettings: "'salt' on, 'liga' off"
                                }}/>
                            </ListItem>
                        </List>

                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            We do not sell personal information to anyone and only share it with third parties who are
                            facilitating the delivery of our services.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            From time to time, PrimeVault receives personal information about individuals from third
                            parties. Typically, information collected from third parties will include further details on
                            your employer or industry. We may also collect your personal data from a third party website
                            (e.g. LinkedIn).
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Use of the PrimeVault Website</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            As is true of most other websites, PrimeVault’s website collects certain information
                            automatically and stores it in log files. The information may include internet protocol (IP)
                            addresses, the region or general location where your computer or device is accessing the
                            internet, browser type, operating system and other usage information about the use of
                            PrimeVault’s website, including a history of the pages you view. We use this information to
                            help us design our site to better suit our users’ needs. We may also use your IP address to
                            help diagnose problems with our server and to administer our website, analyze trends, track
                            visitor movements, and gather broad demographic information that assists us in identifying
                            visitor preferences.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            PrimeVault has a legitimate interest in understanding how members, customers and potential
                            customers use its website. This assists PrimeVault with providing more relevant products and
                            services, with communicating value to our sponsors and corporate members, and with providing
                            appropriate staffing to meet member and customer needs.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Sharing information with third parties</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            The personal information PrimeVault collects from you is stored in one or more databases hosted by third parties located in the United States. These third parties do not use or have access to your personal information for any purpose other than cloud storage and retrieval. On occasion, PrimeVault engages third parties to send information to you, including information about our products, services, and events.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            We do not otherwise reveal your personal data to non-PrimeVault persons or businesses for their independent use unless: (1) you request or authorize it; (2) it’s in connection with PrimeVault-hosted and PrimeVault co-sponsored conferences as described above; (3) the information is provided to comply with the law (for example, compelled by law enforcement to comply with a search warrant, subpoena, or court order), enforce an agreement we have with you, or to protect our rights, property or safety, or the rights, property or safety of our employees or others; (4) the information is provided to our agents, vendors or service providers who perform functions on our behalf; (5) to address emergencies or acts of God; or (6) to address disputes, claims, or to persons demonstrating legal authority to act on your behalf. We may also gather aggregated data about our services and website visitors and disclose the results of such aggregated (but not personally identifiable) information to our partners, service providers, advertisers, and/or other third parties for marketing or promotional purposes.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            The PrimeVault website connects with third party services such as Facebook, LinkedIn, Twitter and others. If you choose to share information from the PrimeVault website through these services, you should review the privacy policy of that service. If you are a member of a third party service, the aforementioned connections may allow that service to connect your visit to our site to your personal data.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Transferring personal data to the U.S.</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            PrimeVault has its headquarters in the United States. Information we collect about you will be processed in the United States. By using PrimeVault’s services, you acknowledge that your personal information will be processed in the United States. PrimeVault endeavors to apply suitable safeguards to protect the privacy and security of your personal data and to use it only consistent with your relationship with PrimeVault and the practices described in this Privacy Statement. PrimeVault also enters into data processing agreements and model clauses with its vendors whenever feasible and appropriate. Since it was founded, PrimeVault has received zero government requests for information.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            For more information or if you have any questions, please contact us at <a href="mailto:privacy@primevault.com">privacy@primevault.com</a>.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Data Subject rights</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            PrimeVault’s Privacy Policy is intended to provide you with information about what personal and non-personal data PrimeVault collects about you and how it is used.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            If you wish to confirm that PrimeVault is processing your personal data, or to have access to the personal data PrimeVault may have about you, please contact us.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            You may also request information about: the purpose of the processing; the categories of personal data concerned; who else outside PrimeVault might have received the data from PrimeVault; what the source of the information was (if you didn’t provide it directly to PrimeVault); and how long it will be stored. You have a right to correct (rectify) the record of your personal data maintained by PrimeVault if it is inaccurate. You may request that PrimeVault erase that data or cease processing it, subject to certain exceptions. You may also request that PrimeVault cease using your data for direct marketing purposes. In many countries, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how PrimeVault processes your personal data. When technically feasible, PrimeVault will—at your request—provide your personal data to you.
                        </p>
                        <br/>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            Reasonable access to your personal data will be provided at no cost. If access cannot be provided within a reasonable time frame, PrimeVault will provide you with a date when the information will be provided. If for some reason access is denied, PrimeVault will provide an explanation as to why access has been denied.
                        </p>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            For questions or complaints concerning the processing of your personal data, you can email us at <a href="mailto:privacy@primevault.com">privacy@primevault.com</a>. Alternatively, if you are located in the European Union, you can also have recourse to the European Data Protection Supervisor or with your nation’s data protection authority.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Data storage and retention</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            Your personal data is stored by the PrimeVault on its servers, and on the servers of the cloud-based database management services the PrimeVault engages, located in the United States. PrimeVault retains service data for the duration of the customer’s business relationship with the PrimeVault and for a period of time thereafter, to analyze the data for PrimeVault’s own operations, and for historical and archiving purposes associated with PrimeVault’s services. PrimeVault retains prospect data until such time as it no longer has business value and is purged from PrimeVault systems. All personal data that PrimeVault controls may be deleted upon verified request from Data Subjects or their authorized agents. For more information on where and how long your personal data is stored, and for more information on your rights of erasure and portability, please contact us at: <a href="mailto:privacy@primevault.com">privacy@primevault.com</a>.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Children’s data</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            Our Platform is not intended for individuals under the age of 18. We do not knowingly attempt to solicit or receive information from children.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Changes to this Privacy Policy</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            We may occasionally update this privacy policy by posting a new version on this page. If we make any material changes, we will notify you by email (sent to the email address specified in your account) or by means of a notice in the Extension. We encourage you to periodically review this page for the latest information on our privacy practices.
                        </p>
                    </section>

                    <section>
                        <h2 className="text-xl font-semibold mt-6 mb-4" style={{
                            fontFamily: "PingFang SC, Arial, sans-serif",
                            fontStyle: "normal",
                            fontWeight: 800,
                            lineHeight: "160%",
                            color: "#E6E6E6",
                            fontFeatureSettings: "'salt' on, 'liga' off",
                        }}>Contact us</h2>
                        <p className="text-[16px]"
                           style={{
                               fontFamily: "PingFang SC, Arial, sans-serif",
                               fontStyle: "normal",
                               fontWeight: 400,
                               lineHeight: "160%",
                               color: "#B7BECB",
                               fontFeatureSettings: "'salt' on, 'liga' off",
                           }}>
                            If you have any questions regarding this Privacy Policy or our privacy practices, or have any suggestions to improve our privacy practices, please contact our Data Protection Office, <a href="mailto:privacy@primevault.com">privacy@primevault.com</a>.
                        </p>
                    </section>
                </div>
            </nav>
            <Footer/>
        </div>
    );
}

export default PrivacyPolicy;
