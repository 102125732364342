import React from "react";

const content = [
    "New paradigm in self custody with zero counterparty risk",
    "Proprietary MPC-powered transaction signing with a decentralized blockchain co-signer",
    "Fine-grained custody governance rules and access controls trustlessly enforced by  private smart contracts on-chain (“Vault Contracts”)",
    "Industry-leading key protection with multi-TEE setup for hardware-level private key isolation",
    "Robust, geographically distributed MPC node clusters for high performance and fault tolerance",
    "Multi-chain, multi-asset, multi-protocol support, including custom assets",
    "Use permissioned vaults to securely manage trading activity on external venues like exchanges",
    "Enterprise-grade secure key backup and recovery: both self- and 3rd party-managed",
];


const CustodySection = () => {
    return (
        <div className="built-differently-section">
            <div className="gradient-card rounded-[48px] px-4 lg:px-[60px] py-[52px] lg:py-[52px] flex gap-4 lg:gap-[63px] mt-[30px] flex justify-between flex-wrap-reverse">
                <div className="space-y-[14px] lg:max-w-[400px]">
                    {content.map((el, index) => (
                        <div className="flex items-start">
                            <div className="different-points" />
                            <p
                                className="text-white-snow text-[16px] leading-[23px]"
                                key={index}
                            >
                                {el}
                            </p>
                        </div>
                    ))}
                </div>
                <img alt="custody-with-configurable-policies" src="/images/Custody-With-Configurable-Policies.png" width={560} className="mx-auto" />
            </div>
        </div>
    );
};

export default React.memo(CustodySection);

