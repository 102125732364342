import React from "react";
import "./App.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactUsForm from "./pages/ContactUsForm";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/request-demo" element={<ContactUsForm/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/" element={<HomePage/>}/>
            </Routes>
        </Router>
    );
}

export default App;
