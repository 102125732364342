import React from 'react';

type NavBarProps = {
    hideButton?: boolean;
}

const NavBar: React.FC<NavBarProps> = ({hideButton = false}) => {
    return (
        <div
            className="flex justify-between items-center mx-4 lg:mx-[78px] py-[26px] border-b border-border border-opacity-10 sm:ml-[50px] sm:mr-[50px] md:ml-[100px] md:mr-[100px] lg:ml-[150px] lg:mr-[150px]">
            <a href="https://www.primevault.com">
                <img src="/images/primevault.svg" alt="primevault" className="h-[33px] object-contain" />
            </a>
            <div className="space-x-11">
                {!hideButton && <button
                    style={{
                        fontFamily: "Inter, Arial, sans-serif",
                        fontWeight: "600",
                    }}
                    className="bg-secondary lg:w-[200px] h-10 px-4 lg:px-0 lg:h-[50px]  rounded-lg  text-xs lg:text-[16px] font-semibold text-white"
                    onClick={() => window.location.href = 'https://tally.so/r/mZjWqA'}
                >
                    Book a demo
                </button>}
            </div>
        </div>
    );
}

export default NavBar;