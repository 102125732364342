import React from "react";

const content = [
    "Seamlessly trade across centralized and decentralized venues",
    "Assets moved to trading venues temporarily, and quickly moved back to your secure vaults, minimizing trading platform risks",
    "Frontrunning protection for on-chain transactions through private mempools",
    "Gas spend optimization based on required speed of settlement",
    "Best-in-class RPC infrastructure with high speed transaction propagation",
];


const TradeSection = () => {
    return (
        <div className="built-differently-section">
            <div className="gradient-card rounded-[48px] px-4 lg:px-[60px] py-[52px] flex gap-4 lg:gap-[63px] mt-[30px] flex justify-between flex-wrap-reverse">
                <div className="space-y-[14px] lg:max-w-[504px]">
                    {content.map((el, index) => (
                        <div className="flex items-start lg:mt-[20px]">
                            <div className="different-points" />
                            <p
                                className="text-white-snow text-[16px] leading-[23px] "
                                key={index}
                            >
                                {el}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="flex w-full lg:w-max">
                    <img alt="seamless-trades" src="/images/Seamless-Trades.png" className="newTradesData h-[250px] lg:h-[500px] lg:w-[250px] w-full lg:w-1/2" />
                    <img alt="trades-with-spend-limit" src="/images/Trades-With-Spend-Limit.png" className="newTradesData h-[250px] lg:h-[500px] lg:w-[250px] w-full lg:w-1/2" />
                </div>
            </div>
        </div>
    );
};

export default React.memo(TradeSection);

