import React from "react";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import KeyIcon from '@mui/icons-material/Key';
import MemoryIcon from '@mui/icons-material/Memory';
import DevicesIcon from '@mui/icons-material/Devices';
import { Box } from "@mui/material";

const ExploreSection = () => {
    return (
        <section id="explore-defi" className="explore-section">
            <p style={{
                color: "#E6E6E6",
                fontFamily: 'SF Pro Display, Arial, sans-serif',
                fontWeight: "500 !important",
            }} className="max-w-[709px] text-center lg:leading-[59px] mx-auto lg:text-[40px] text-2xl">
                Explore the frontiers of DeFi with secure on-chain automations{" "}
            </p>
            <div className="gap-12 lg:gap-0 flex mt-12 lg:mt-[120px] items-center justify-between lg:flex-row flex-col-reverse">
                <div className="relative explore-content">
                    <div className="max-w-[492px] space-y-10 mt-2.5">
                        <Box className='flex space-x-4' sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '35px',
                                    height: '35px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    flexShrink: 0,
                                    borderRadius: '12.518px',
                                    background: 'rgba(104, 116, 143, 0.20)',
                                    backdropFilter: 'blur(31.296083450317383px)'
                                }}
                            >
                                <AccountBalanceWalletIcon sx={{ color: '#FFFFFF' }} />
                            </Box>

                            <p
                                style={{
                                    fontFamily: 'PingFang SC, Arial, sans-serif',
                                    fontSize: "18px",
                                    color: "#FFF",
                                    lineHeight: "24px",
                                    fontWeight: "400 !important",
                                }}
                            >
                                Secure your DeFi advantage with our generalized non-custodial
                                wallet automations
                            </p>
                        </Box>
                        <Box className='flex space-x-4' sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '35px',
                                    height: '35px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    flexShrink: 0,
                                    borderRadius: '12.518px',
                                    background: 'rgba(104, 116, 143, 0.20)',
                                    backdropFilter: 'blur(31.296083450317383px)'
                                }}
                            >
                                <KeyIcon sx={{ color: '#FFFFFF' }} />
                            </Box>
                            <p
                                style={{
                                    fontFamily: 'PingFang SC, Arial, sans-serif',
                                    fontSize: "18px",
                                    color: "#FFF",
                                    lineHeight: "24px",
                                    fontWeight: "400 !important",
                                }}
                            >
                                Pre-authorize on-chain transfers, trades and risk strategies that
                                run autonomously when configured conditions are satisfied via
                                PrimeVault’s Autonomous Signing Infrastructure (ASI)
                            </p>
                        </Box>
                        <Box className='flex space-x-4' sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '35px',
                                    height: '35px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    flexShrink: 0,
                                    borderRadius: '12.518px',
                                    background: 'rgba(104, 116, 143, 0.20)',
                                    backdropFilter: 'blur(31.296083450317383px)'
                                }}
                            >
                                <MemoryIcon sx={{ color: '#FFFFFF' }} />
                            </Box>
                            <p
                                style={{
                                    fontFamily: 'PingFang SC, Arial, sans-serif',
                                    fontSize: "18px",
                                    color: "#FFF",
                                    lineHeight: "24px",
                                    fontWeight: "400 !important",
                                }}
                            >
                                Decentralized MPC node clusters that execute pre-authorized
                                transactions with minimal trust requirements
                            </p>
                        </Box>
                        <Box className='flex space-x-4' sx={{ alignItems: 'center' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    width: '35px',
                                    height: '35px',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '8px',
                                    flexShrink: 0,
                                    borderRadius: '12.518px',
                                    background: 'rgba(104, 116, 143, 0.20)',
                                    backdropFilter: 'blur(31.296083450317383px)'
                                }}
                            >
                                <DevicesIcon sx={{ color: '#FFFFFF' }} />
                            </Box>
                            <p
                                style={{
                                    fontFamily: 'PingFang SC, Arial, sans-serif',
                                    fontSize: "18px",
                                    color: "#FFF",
                                    lineHeight: "24px",
                                    fontWeight: "400 !important",
                                }}
                            >
                                Natively DeFi experience that rivals CeFi and centralized
                                exchanges
                            </p>
                        </Box>
                    </div>
                </div>
                <div>
                    <img alt="explore" src="/images/explore.png" height={345} width={571} />
                </div>
            </div>
        </section>
    );
};

export default React.memo(ExploreSection);
