import React from "react";


const InvestorsSection = () => {
    return (
        <section id="investors" className="investors-section">
            <p style={{
                color: "#E6E6E6",
                fontFamily: "SF Pro Display, Arial, sans-serif",
                lineHeight: "51.6px",
                fontWeight: "500 !important",
            }} className="font-medium text-center lg:text-[40px] text-2xl">
                Backed by
            </p>

            <div className="mt-[71px] max-w-[1248px] mx-auto grid grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/y-combinator.png"
                        height={60}
                        width={200}
                        className="object-contain"
                        alt="combinator"
                    />
                </div>
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/arrington-capital.png"
                        height={56}
                        width={142}
                        className="object-contain"
                        alt="arrington"
                    />
                </div>
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/hashkey-capital.png"
                        height={48}
                        width={146}
                        className="object-contain"
                        alt="hashkey-capital"
                    />
                </div>
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/cogitent-ventures.png"
                        height={102}
                        width={204}
                        className="object-contain"
                        alt="cogitent-ventures"
                    />
                </div>
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/alchemy.png"
                        height={41}
                        width={194}
                        className="object-contain"
                        alt="alchemy.png"
                    />
                </div>
                <div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">
                    <img
                        src="/images/near.png"
                        height={36}
                        width={137}
                        className="object-contain"
                        alt="near.png"
                    />
                </div>
                {/*<div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">*/}
                {/*    <img*/}
                {/*        src="/images/secret-network.png"*/}
                {/*        height={64}*/}
                {/*        width={172}*/}
                {/*        className="object-contain"*/}
                {/*        alt="secret-network"*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="gradient-card h-[120px] flex items-center justify-center rounded-[28px]">*/}
                {/*    <img*/}
                {/*        src="/images/oasis-foundation.png"*/}
                {/*        height={60}*/}
                {/*        width={200}*/}
                {/*        className="object-contain"*/}
                {/*        alt="oasis-foundation"*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
        </section>
    );
};

export default React.memo(InvestorsSection);
