import React from "react";

const SecuritySection = () => {
    return (
        <section id="security" className="security-container">
            <div className="security-section flex flex-col md:flex-row items-center justify-end md:justify-between">
                <p
                    style={{
                        fontFamily: "SF Pro Display, Arial, sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500 !important",
                        lineHeight: "58.05px",
                        color: "#E6E6E6",
                    }}
                    className="lg:text-[40px] text-2xl w-full md:w-[391px] font-medium text-center md:text-left"
                >
                    Industry-leading security and privacy
                </p>

                <div className="flex flex-col md:flex-row items-center md:mr-6 md:space-x-6 space-y-6 md:space-y-0">
                    <div
                        className="security-card-gradient w-full md:w-[367px] h-[150px] md:h-[244px] px-4 md:px-6 rounded-[28px] flex items-center justify-center flex-col"
                        style={{
                            maxWidth: '90%',
                        }}
                    >
                        <img
                            alt="halborn"
                            src="/images/halborn-logo.svg"
                            style={{
                                height: '40%',
                                flexShrink: 0,
                                maxWidth: '60%',
                            }}
                        />
                        <p
                            style={{
                                fontFamily: 'PingFang SC, Arial, sans-serif',
                                color: "#B7BECB",
                                lineHeight: "23.488px",
                                fontWeight: "400 !important",
                            }}
                            className="text-center mt-7 lg:text-[20px] text-l"
                        >
                            Audited by Halborn
                        </p>
                    </div>

                    <div
                        className="security-card-gradient w-full md:w-[367px] h-[150px] md:h-[244px] px-4 md:px-6 rounded-[28px] flex items-center justify-center flex-col"
                        style={{
                            maxWidth: '90%',
                        }}
                    >
                        <img
                            alt="soc2"
                            src="/images/soc2.png"
                            style={{
                                height: '40%',
                                flexShrink: 0,
                                maxWidth: '60%',
                            }}
                        />
                        <p
                            style={{
                                fontFamily: 'PingFang SC, Arial, sans-serif',
                                color: "#B7BECB",
                                lineHeight: "23.488px",
                                fontWeight: "400 !important",
                            }}
                            className="text-center mt-7 lg:text-[20px] text-l"
                        >
                            SOC 2 certified
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default React.memo(SecuritySection);
