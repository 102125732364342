import React from "react";

const content = [
    "Launch your token and manage token contract securely within PrimeVault ",
    "Automate distribution and claim of unlocked tokens for investors",
    "Tokenize any financial products",

];


const TokenizationSection = () => {
    return (
        <div className="built-differently-section">
            <div className="gradient-card rounded-[48px] px-4 lg:px-[60px] py-[52px] flex gap-4 lg:gap-[63px] mt-[30px] flex justify-between flex-wrap-reverse">
                <div className="space-y-[14px] lg:max-w-[504px]">
                    {content.map((el, index) => (
                        <div className="flex items-start lg:mt-[80px]">
                            <div className="different-points" />
                            <p
                                className="text-white-snow text-[16px] leading-[23px]"
                                key={index}
                            >
                                {el}
                            </p>
                        </div>
                    ))}
                </div>
                <img alt="tokenization-and-token-management" src="/images/Token-Management.png" width={500} className="mx-auto" />
            </div>
        </div>
    );
};

export default React.memo(TokenizationSection);

