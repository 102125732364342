import React from "react";

const content = [
    "Embed PrimeVault’s secure MPC wallets and trading tools into your product",
    "Sandbox access with specifications and dedicated endpoints for testing",
    "Lightning fast response times",
    "360 degree observability on requests",
];



 
const BuildwithPrimeSection = () => {
    return (
        <div className="built-differently-section">
            <div className="gradient-card rounded-[48px] px-4 lg:px-[60px] py-[52px] flex gap-4 lg:gap-[63px] mt-[30px] flex justify-between flex-wrap-reverse">
                <div className="space-y-[14px] lg:max-w-[504px]">
                    {content.map((el, index) => (
                        <div className="flex items-start mt-[15px]">
                            <div className="different-points" />
                            <p
                                className="text-white-snow text-[16px] leading-[23px]"
                                key={index}
                            >
                                {el}
                            </p>
                        </div>
                    ))}
                </div>
                <img alt="sdk-support" src="/images/SDK-Support.png" width={550} className="mx-auto" />
            </div>
        </div>
    );
};

export default React.memo(BuildwithPrimeSection);

