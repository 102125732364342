import React from "react";

const treasuries = [
  {
    image: "/images/token_operations.png",
    content:
      "Fulfil all your token operations - minting, burning, vesting, distribution, and more - from one solution and reduce costs on multiple products and in-house development",
  },
  {
    image: "/images/governance.png",
    content:
      "Restrict your exposure to both internal and external actors by setting granular access controls and system governance across your organization",
  },
  {
    image: "/images/automate_treasury.png",
    content:
      "Automate treasury operations such as payroll, liquidity management in DeFi etc., and focus your developer time on your customer-facing product and eliminate effort on internal solutions",
  },
  {
    image: "/images/trade_assets.png",
    content:
      "Seamlessly trade assets with best price executions from within PrimeVault",
  },
  {
    image: "/images/compliance.png",
    content:
      "Save time and effort required for taxes and compliance by generating detailed reporting of assets across self custody vaults, DeFi and exchanges",
  },
];

const Treasuries = () => {
  return (
    <section id="treasuries" className="treasuries-section mb-12 lg:mb-0">
      <div>
        <p style={{
          fontFamily: 'SF Pro Display, Arial, sans-serif',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '129%',
          color: '#E6E6E6',
        }} className="text-center font-medium mb-5 lg:text-[40px] text-2xl">
          Web3 Treasuries
        </p>
        <p style={{
          fontFamily: 'PingFang SC, Arial, sans-serif',
          fontSize: '20px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '134%',
          color: '#B7BECB',
          textAlign: 'center',
          letterSpacing: '1px',
        }} className="text-center w-[364px] mx-auto">
          Token issuance, vesting, distribution and payments
        </p>
      </div>
      <div className="flex flex-col space-y-4 mx-auto treasuries-list">
        {treasuries.map((el, index) => (
          <div className="gap-4 flex items-center" key={index}>
            <img alt="Restrict" src={el.image} className="h-10 w-10" />
            <p style={{
              fontFamily: 'PingFang SC, Arial, sans-serif',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '160%',
              color: '#E6E6E6',
              fontFeatureSettings: "'salt' on, 'liga' off",
            }} >{el.content}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default React.memo(Treasuries);
