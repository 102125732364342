import React from "react";

const institutions = [
  {
    image: "/images/operational_risk.png",
    content:
      "Increase efficiency and minimize operational risk through single window access to your entire portfolio",
  },
  {
    image: "/images/defi.png",
    content:
      "Make the most of your assets through seamless access to new DeFi opportunities",
  },
  {
    image: "/images/automations.png",
    content:
      "Maximize your productivity by eliminating manual interventions and automating on-chain transactions",
  },
  {
    image: "/images/risk_automations.png",
    content:
      "Sleep at peace with automated risk management protecting you from any shocks in prices of your assets",
  },
  {
    image: "/images/trading_strategies.png",
    content:
      "Reduce lead time for implementing new trading strategies and save developer hours",
  },
  {
    image: "/images/access_control.png",
    content:
      "Minimize your overall exposure and reduce operational risk by setting granular policies for access controls and system governance across your team members",
  },
];

const Institutions = () => {
  return (
    <section id="institutions" className="institution-section relative mb-12 lg:mb-0">
      <div className="lg:mt-[114px] mt-10">
        <p
          style={{
            fontFamily: "SF Pro Display, Arial, sans-serif",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "129%",
            color: "#E6E6E6",
          }}
          className="text-white-snow text-center  font-medium mb-5 lg:text-[40px] text-2xl"
        >
          Institutions
        </p>
        <p
          style={{
            fontFamily: "PingFang SC, Arial, sans-serif",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "134%",
            color: "#B7BECB",
            textAlign: "center",
            letterSpacing: "1px",
          }}
          className="text-secondary-text text-center lg:w-[364px] mx-auto"
        >
          Funds, Market Makers, Exchanges, Prop Traders
        </p>
      </div>
      <div className="flex flex-col space-y-4 mx-auto institutions-list">
        {institutions.map((el, index) => (
          <div className="gap-4 flex items-center" key={index}>
            <img alt="Institutions" src={el.image} className="h-10 w-10" />
            <p
              className="text-[16px]"
              style={{
                fontFamily: "PingFang SC, Arial, sans-serif",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "160%",
                color: "#E6E6E6",
                fontFeatureSettings: "'salt' on, 'liga' off",
              }}
            >
              {el.content}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default React.memo(Institutions);
