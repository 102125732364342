import React, { SVGProps } from "react";

export const GradientDot: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_533_4286)">
        <circle cx="19" cy="19" r="7" fill="url(#paint0_linear_533_4286)" />
      </g>
      <defs>
        <filter
          id="filter0_d_533_4286"
          x="0"
          y="0"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_533_4286"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.275 0 0 0 0 0.652003 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_533_4286"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_533_4286"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_533_4286"
          x1="19"
          y1="12"
          x2="25.1765"
          y2="19.9882"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#0068EC" />
          <stop offset="1" stop-color="#00BBF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
