import React, {memo} from "react";
import {Typography, Box} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

type ContactUsProps = {
    handleOpen?: () => void;
};

const ContactUsForm: React.FC<ContactUsProps> = ({handleOpen}) => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [phoneNumber, setPhoneNumber] = React.useState("");

    const handlePhoneChange = (phone: string) => {
        setPhoneNumber(phone);
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData(event.target);
        const formProps = Object.fromEntries(formData);

        formProps["Phone Number"] = phoneNumber;

        try {
            const response = await fetch("https://api.sheetmonkey.io/form/fvNmxEP3HbnRpuiS3S4Cto", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formProps),
            });

            if (response.ok) {
                setIsSubmitted(true);
            } else {
                alert("Submission failed, please try again.");
            }
        } catch (error) {
            // Handle network errors
            console.error("Submission error:", error);
            alert("Submission error, please check your connection and try again.");
        }
    };

    return (
        <div>
            <NavBar hideButton/>
            <div className="contact-us-page p-4 lg:p-0">
                <div className="contact-us-section built-differently-section pt-12 lg:pt-[96px] overflow-y-hidden">
                    {/*<Box*/}
                    {/*  sx={{*/}
                    {/*    color: "white",*/}
                    {/*    padding: "10px",*/}
                    {/*    display: "flex",*/}
                    {/*    alignItems: "center",*/}
                    {/*    justifyContent: "end",*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  <CloseIcon*/}
                    {/*    onClick={handleOpen}*/}
                    {/*    sx={{ color: "white", cursor: "pointer" }}*/}
                    {/*  />*/}
                    {/*</Box>*/}
                    {
                        isSubmitted ? (
                            <div style={{textAlign: "center", marginTop: "20px"}}>
                                <Typography
                                    sx={{
                                        color: "#E6E6E6",
                                        textAlign: "center",
                                        fontSize: "18px",
                                        fontWeight: "500 !important",
                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                    }}
                                >
                                    Thank you for contacting us.
                                    <br/>We will get back to you soon!
                                </Typography>
                            </div>
                        ) : (
                            <><Box className="flex flex-col items-center justify-center pb-6">
                                <Typography
                                    sx={{
                                        color: "#E6E6E6",
                                        textAlign: "center",
                                        fontSize: "45px",
                                        fontWeight: "500 !important",
                                        lineHeight: "58.05px",
                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                    }}
                                >
                                    Contact Us
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#777B82",
                                        fontSize: "18px",
                                        display: "flex",
                                        lineHeight: "28.8px",
                                        textAlign: "center",
                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                    }}
                                >
                                    Launching a token? Or looking to self-custody your treasury securely?
                                </Typography>
                                <Typography
                                    sx={{
                                        color: "#777B82",
                                        fontSize: "18px",
                                        display: "flex",
                                        lineHeight: "28.8px",
                                        textAlign: "center",
                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                    }}
                                >
                                    Join the network of token projects, VC funds, market makers, liquidity providers today!
                                </Typography>
                            </Box>
                                <form className="rounded px-8 pt-2 pb-8" style={{overflow: "hidden"}}
                                      onSubmit={handleSubmit} id="form">
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                First Name
                                                <span
                                                    className="text-[14px] leading-[20px] mb-2 text-[#E01E5A]"
                                                    style={{
                                                        fontWeight: 500,
                                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                                    }}
                                                >
                *
              </span>
                                            </label>
                                            <input
                                                className=" w-full bg-[#0B1729] text-white rounded-[8px] py-2 px-2"
                                                id="first-name"
                                                type="text"
                                                placeholder="First Name"
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                                name="First Name"
                                                required
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="text-[14px] leading-[20px] text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Last Name
              {/*                                  <span*/}
              {/*                                      className="text-[14px] leading-[20px] text-[#E01E5A]"*/}
              {/*                                      style={{*/}
              {/*                                          fontWeight: 500,*/}
              {/*                                          fontFamily: "SF Pro Display, Arial, sans-serif",*/}
              {/*                                      }}*/}
              {/*                                  >*/}
              {/*  **/}
              {/*</span>*/}
                                            </label>
                                            <input
                                                className=" w-full bg-[#0B1729] text-white  rounded-[8px] py-2 px-2"
                                                id="last-name"
                                                type="text"
                                                placeholder="Last Name"
                                                name="Last Name"
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full px-3">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Work Email
                                                <span
                                                    className="text-[14px] leading-[20px]  text-[#E01E5A]"
                                                    style={{
                                                        fontWeight: 500,
                                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                                    }}
                                                >
                *
              </span>
                                            </label>
                                            <input
                                                className=" w-full bg-[#0B1729] text-white  rounded-[8px] py-2 px-2"
                                                id="email"
                                                type="email"
                                                name="Email"
                                                required
                                                placeholder="test@example.com"
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Company
                                                <span
                                                    className="text-[14px] leading-[20px] text-[#E01E5A]"
                                                    style={{
                                                        fontWeight: 500,
                                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                                    }}
                                                >
                *
              </span>
                                            </label>
                                            <input
                                                className=" w-full bg-#878D98-200  text-white bg-[#0B1729]  rounded-[8px] py-2 px-2"
                                                id="company"
                                                type="text"
                                                name="Company"
                                                required
                                                placeholder="Company"
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Job Title
                                                <span
                                                    className="text-[14px] leading-[20px] mb-2 text-[#E01E5A]"
                                                    style={{
                                                        fontWeight: 500,
                                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                                    }}
                                                >
                *
              </span>
                                            </label>
                                            <input
                                                className=" w-full bg-[#0B1729] text-white  rounded-[8px] py-2 px-2"
                                                id="job-title"
                                                type="text"
                                                placeholder="Job Title"
                                                name="Job Title"
                                                required
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Phone Number
                                                {/*                  <span*/}
                                                {/*                      className="text-[14px] leading-[20px] mb-2 text-[#E01E5A]"*/}
                                                {/*                      style={{fontWeight: 500, fontFamily: "SF Pro Display, Arial, sans-serif",}}*/}
                                                {/*                  >*/}
                                                {/*  **/}
                                                {/*</span>*/}
                                            </label>
                                            <PhoneInput
                                                inputClass="selected-flag"
                                                country={"us"}
                                                onChange={handlePhoneChange}
                                                inputStyle={{
                                                    backgroundColor: "#0B1729",
                                                    borderRadius: "8px",
                                                    border: "2px solid #2D3C52",
                                                    padding: "12px 50px",
                                                    width: "100%",
                                                    color: "white",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>

                                        <div className="w-full md:w-1/2 px-3">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Country
                                                <span
                                                    className="text-[14px] leading-[20px] mb-2 text-[#E01E5A]"
                                                    style={{
                                                        fontWeight: 500,
                                                        fontFamily: "SF Pro Display, Arial, sans-serif",
                                                    }}
                                                >
                *
              </span>
                                            </label>
                                            <input
                                                className=" w-full bg-[#0B1729] text-white  rounded-[8px] py-2 px-2"
                                                id="country"
                                                type="text"
                                                placeholder="Country"
                                                name="Country"
                                                required
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/*      <div className="flex flex-wrap -mx-3 mb-2">*/}
                                    {/*          <div className="w-full px-3">*/}
                                    {/*              <label*/}
                                    {/*                  className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"*/}
                                    {/*                  style={{fontWeight: 500, fontFamily: "SF Pro Display, Arial, sans-serif",}}*/}
                                    {/*              >*/}
                                    {/*                  Country*/}
                                    {/*                  <span*/}
                                    {/*                      className="text-[14px] leading-[20px] mb-2 text-[#E01E5A]"*/}
                                    {/*                      style={{fontWeight: 500, fontFamily: "SF Pro Display, Arial, sans-serif",}}*/}
                                    {/*                  >*/}
                                    {/*  **/}
                                    {/*</span>*/}
                                    {/*              </label>*/}
                                    {/*              <input*/}
                                    {/*                  className=" w-full bg-[#0B1729] text-white rounded-[8px] py-2 px-2"*/}
                                    {/*                  id="grid-password"*/}
                                    {/*                  type="text"*/}
                                    {/*                  placeholder="USA"*/}
                                    {/*                  style={{border: "2px solid #2D3C52", fontFamily: "SF Pro Display, Arial, sans-serif",}}*/}
                                    {/*              />*/}
                                    {/*          </div>*/}
                                    {/*      </div>*/}
                                    <div className="flex flex-wrap -mx-3 mb-2">
                                        <div className="w-full px-3">
                                            <label
                                                className="text-[14px] leading-[20px] mb-2 text-[#E6E6E6]"
                                                style={{
                                                    fontWeight: 500,
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            >
                                                Message
                                            </label>
                                            <textarea
                                                rows={3}
                                                className=" w-full bg-[#0B1729] text-white rounded-[8px] py-2 px-2"
                                                id="message"
                                                name="Message"
                                                placeholder="Tell us more about how we can help you.
Which feature(s) are you interested in using?
How did you hear about PrimeVault?"
                                                style={{
                                                    border: "2px solid #2D3C52",
                                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            textAlign: "center",
                                            justifyContent: "center",
                                        }}
                                        className='pt-6'
                                    >
                                        <button
                                            style={{
                                                fontFamily: "Inter, Arial, sans-serif",
                                                fontWeight: "600",
                                                backgroundColor: "#007BFF",
                                                width: "200px",
                                                height: "50px",
                                                borderRadius: "4px",
                                                fontSize: "16px",
                                                color: "#FFFFFF",
                                                filter: "drop-shadow(0px 0px 8px rgba(0, 137, 214, 0.5))",
                                                cursor: "pointer",
                                                border: "none",
                                                outline: "none",
                                            }}
                                            className="text-sm lg:text-[16px] font-semibold"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </>)}
                </div>
            </div>
            <Footer hideContactUs />
        </div>
    );
};
export default memo(ContactUsForm);
