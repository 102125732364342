import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import CustodySection from "./CustodySection";
import TradeSection from "./TradeSection";
import RiskManagementSection from "./RiskManagementSection";
import BuildwithPrimeSection from "./BuildwithPrimeVault";
import TokenizationSection from "./TokenizationSection";
import { GradientDot } from "./GradientDot";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const BuiltDifferently = () => {

    const steps = [
        {
            title: "Custody",
            content: "Institutional self custody, revolutionized",
        },
        {
            title: "Trade",
            content: "High performance hybrid trading infrastructure",
        },
        {
            title: "Risk  Management",
            content: "Continuous risk monitoring and mitigation",
        },
        {
            title: "Web3 Treasury & Tokenization",
            content: "Token issuance, vesting, distribution and payments",
        },
        {
            title: "Build with PrimeVault",
            content: "API and flexible deployments",
        },
    ];
    const [selectedStep, setSelectedStep] = useState(0);

    return (
        <section
            className="built-section-card built-differently-section pt-12 lg:pt-[96px]"
            id="built-differently"
        >
            <p
                style={{
                    fontFamily: "SF Pro Display, Arial, sans-serif",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "129%",
                    color: "#E6E6E6",
                }}
                className="text-white-snow text-center font-medium mb-5 lg:text-[40px] text-l"
            >
                Built for enterprise scale and security
            </p>
            <div className="grid grid-cols-5 mt-[76px]">
                {steps.map((step, index) => (
                    <div key={step.title}>
                        <div
                            className="-ml-2 flex items-center mr-2 cursor-pointer"
                            onClick={() => setSelectedStep(index)}
                        >
                            <div>
                                {index === selectedStep ? (
                                    <div className="h-4 w-4 relative">
                                        <GradientDot className="h-10 w-10 absolute -top-3 -left-3" />
                                    </div>
                                ) : (
                                    <svg
                                        className="h-4 w-4"
                                        viewBox="0 0 15 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle
                                            cx="7.84766"
                                            cy="7"
                                            r="7"
                                            fill="#F7F8F8"
                                            fill-opacity="0.2"
                                        />
                                    </svg>
                                )}
                            </div>

                            <div className="h-px w-full bg-border bg-opacity-20" />
                        </div>
                        <div>
                            <p
                                style={{ fontFamily: "SF Pro Display, Arial, sans-serif" }}
                                className="carousel text-[10px] lg:text-[24px] font-bold text-white-snow mt-4 lg:mt-[38px] -left-1 relative"
                            >
                                {step.title}
                            </p>
                            <p
                                style={{
                                    fontFamily: "SF Pro Display, Arial, sans-serif",
                                }}
                                className="text-secondary-text text-[14px] leading-[19px] mt-4 hidden lg:block lg:py-[10px] lg:pr-[5px]"
                            >
                                {step.content}
                            </p>
                        </div>
                    </div>
                ))}
            </div>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}>
                <div
                    onClick={() => setSelectedStep(prevStep => Math.max(0, prevStep - 1))}
                    style={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        backgroundColor: "transparent",
                        border:"1px solid #E6E6E6",
                        padding: "10px",
                        opacity: selectedStep === 0 ? 0.5 : 1,
                    }}
                >
                    <ArrowBackIcon sx={{ color: "#E6E6E6", cursor: "pointer" }} />
                </div>
                <div
                    onClick={() => setSelectedStep(prevStep => Math.min(steps.length - 1, prevStep + 1))}
                    style={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        backgroundColor: "transparent",
                        border: "1px solid #E6E6E6",
                        padding: "10px",
                        marginLeft: "15px",
                        opacity: selectedStep === steps.length - 1 ? 0.5 : 1,
                    }}
                >
                    <ArrowForwardIcon sx={{ color: "#E6E6E6", cursor: "pointer" }} />
                </div>
            </div>

            <Slide
                timeout={400}
                in={selectedStep === 0}
                direction={"right"}
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <CustodySection />
                </div>
            </Slide>
            <Slide
                timeout={400}
                in={selectedStep === 1}
                direction="right"
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <TradeSection />
                </div>
            </Slide>
            <Slide
                timeout={400}
                in={selectedStep === 2}
                direction="right"
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <RiskManagementSection />
                </div>
            </Slide>
            <Slide
                timeout={400}
                in={selectedStep === 3}
                direction="right"
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <TokenizationSection />
                </div>
            </Slide>
            <Slide
                timeout={400}
                in={selectedStep === 4}
                direction="right"
                mountOnEnter
                unmountOnExit
            >
                <div>
                    <BuildwithPrimeSection />
                </div>
            </Slide>
        </section>
    );
};
export default React.memo(BuiltDifferently);
