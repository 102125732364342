import React from "react";
import Header from "../components/Header";
import BuiltDifferently from "../components/BuiltDifferently";
import Institutions from "../components/Institutions";
import Treasuries from "../components/Treasuries";
import ExploreSection from "../components/Explore";
import InvestorsSection from "../components/Investors";
import Footer from "../components/Footer";
import SecuritySection from "../components/Security";


const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="content-section p-4 lg:p-0">
        <BuiltDifferently />
        <Institutions />
        <Treasuries />
        <ExploreSection/>
        <SecuritySection />
        <InvestorsSection />
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(HomePage);
